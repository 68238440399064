
import ValidationMixin from "@/mixins/validation";
import { SystemUser, UserTypes } from "@/store/modules/user/user.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const userX = namespace("User");

@Component({
  components: {
    DepartmentSelect: () =>
      import("@/components/department/DepartmentSelect.vue"),
    RoleSelect: () => import("@/components/role/RoleSelect.vue"),
  },
})
export default class EditUser extends Mixins(ValidationMixin) {
  @userX.State(UserTypes.UPDATE_USER_STATE)
  public updateUser!: DefaultState;

  @userX.State(UserTypes.USER_DATA)
  public userUser!: SystemUser;

  @userX.Mutation(UserTypes.SET_UPDATE_USER_DIALOG)
  public setUpdateUser!: (updateUser: boolean) => void;

  @userX.Action(UserTypes.UPDATE_USER)
  public saveUser!: (farmer: SystemUser) => Promise<void>;

  public updateUserStep = 1;

  public isValid = false;

  public user: SystemUser = {
    id: -1,
    firstname: "",
    name: "",
    lastname: "",
    username: "",
    email: "",
    phone: "",
    department: "",
    role: "",
    active: false,
    createdAt: new Date(),
  };

  @Ref("form") public formRef!: Validator;

  closeUpdateUserDialog(): void {
    this.setUpdateUser(false);
  }

  async saveUserToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveUser(this.user);
    }
  }

  @Watch("userUser")
  onDataChanged(value?: SystemUser): void {
    this.user = Object.assign({}, value);
  }

  mounted(): void {
    this.user = Object.assign({}, this.userUser);
  }
}
